import { Routes } from '@angular/router';


export const content: Routes = [
  {
    path: 'sample-page',
    loadChildren: () => import('../../components/sample/sample.module').then(m => m.SampleModule)
  },
  {
    path: 'mitra',
    loadChildren: () => import('../../components/mitra/mitra.module').then(m => m.MitraModule)
  },
  {
    path: 'development',
    loadChildren: () => import('../../components/development/development.module').then(m => m.DevelopmentModule)
  },
  {
    path: 'consumer',
    loadChildren: () => import('../../components/consumer/consumer.module').then(m => m.ConsumerModule)
  },
  {
    path: 'management',
    loadChildren: () => import('../../components/management/management.module').then(m => m.ManagementModule)
  },
  {
    path: 'dashboard',
    loadChildren: () => import('../../components/dashboard/dashboard.module').then(m => m.DashboardModule)
  },
  {
    path: 'account',
    loadChildren: () => import('../../components/account/account.module').then(m => m.AccountModule)
  }
];
