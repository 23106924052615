<div class="media profile-media">
    <img class="b-r-10" src="assets/images/dashboard/new_profil.png" alt="">
    <div class="media-body"><span>{{getFullname()}}</span>
        <p class="mb-0 font-roboto">{{getGroup()}} <i class="middle fa fa-angle-down"></i></p>
    </div>
</div>
<ul class="profile-dropdown onhover-show-div">
    <li>
        <a href="/account/profile">
            <app-feather-icons [icon]="'user'"></app-feather-icons><span>Profile </span></a>
    </li>
    <!--
    <li>
        <a href="#">
            <app-feather-icons [icon]="'mail'"></app-feather-icons><span>Inbox</span></a>
    </li>
    <li>
        <a href="#">
            <app-feather-icons [icon]="'file-text'"></app-feather-icons><span>Taskboard</span></a>
    </li>
    -->
    <!-- <li>
        <a href="/account/setting">
            <app-feather-icons [icon]="'settings'"></app-feather-icons><span>Settings</span></a>
    </li> -->
    <li>
        <a (click)="logOut()">
            <app-feather-icons [icon]="'log-out'"></app-feather-icons><span>Log Out</span></a>
    </li>
</ul>