import { Component, OnInit } from '@angular/core';
import { LayoutService } from '../../services/layout.service';

@Component({
  selector: 'app-floating-text',
  templateUrl: './floating-text.component.html',
  styleUrls: ['./floating-text.component.scss']
})
export class FloatingTextComponent implements OnInit {

  public today: number = Date.now();

  constructor(
    public layout:LayoutService
  ) { }

  ngOnInit(): void {
  }

}
