import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ProfileService } from 'src/app/service/profile.service';
import { ServerService } from 'src/app/service/server.service';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-my-account',
  templateUrl: './my-account.component.html',
  styleUrls: ['./my-account.component.scss']
})
export class MyAccountComponent implements OnInit {

  constructor(private profile: ProfileService,
    private serverService: ServerService,
    private router: Router) { }

  fullname: string
  group: string

  ngOnInit() {
    if(localStorage.getItem('userInfo')){
      let userInfo = JSON.parse(localStorage.getItem('userInfo'));
      this.fullname = userInfo.fullname;
      this.group = userInfo.group;
    }
  }

  getFullname() {
    return this.fullname;
  }

  getGroup() {
    return this.group;
  }

  logOut(){
    if(localStorage.getItem('userInfo')){
      let user = JSON.parse(localStorage.getItem('userInfo'))
      this.serverService.doPost("",environment.path.base + environment.path.partner.base + environment.path.partner.user+'/logout/'+user.id).subscribe(async resp => {
        if(resp){
          console.log("Clear session DONE.")
        }
      })
    }
    sessionStorage.clear();
    localStorage.clear()
    this.router.navigate(['landing/home']);
  }

}
