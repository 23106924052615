import { Routes } from '@angular/router';

export const full: Routes = [
    {
        path: 'landing',
        loadChildren: () => import('../../pages/landing/landing.module').then(m => m.LandingModule),
    },
    {
      path: 'error',
      loadChildren: () => import('../../pages/error/error.module').then(m => m.ErrorModule),
    },
    {
      path: 'auth',
      loadChildren: () => import('../../pages/auth/auth.module').then(m => m.AuthModule),
    },
    {
      path: 'email-activation/:token',
      loadChildren: () => import('../../pages/email-activation/emailactivation.module').then(m => m.EmailActivationModule),
    },
    {
      path: 'reset-password/:token',
      loadChildren: () => import('../../pages/reset-password/reset-password.module').then(m => m.ResetPasswordModule),
    }
];

