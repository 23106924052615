import { Component, ViewEncapsulation, HostListener } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Menu, NavService } from '../../services/nav.service';
import { LayoutService } from '../../services/layout.service';
import { Menus } from 'src/app/shared/components/sidebar/menu';


@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SidebarComponent {

  public iconSidebar;
  public menuItems: Menus[];
  public url: any;
  public fileurl: any;
  public urlTerm:string

  // For Horizontal Menu
  public margin: any = 0;
  public width: any = window.innerWidth;
  public leftArrowNone: boolean = true;
  public rightArrowNone: boolean = false;

  constructor(private router: Router, public navServices: NavService,
    public layout: LayoutService) {
    this.navServices.items.subscribe(menuItems => {
      if(localStorage.getItem('menuInfo')){
        var listmenufromdb = JSON.parse(localStorage.getItem('menuInfo'));

        var menuFromDb =[{url: '/dashboard/default', displayName: 'Beranda', icon: 'home',type: 'link'}] as Menus[];

        listmenufromdb.sort((o1, o2) => o1.ordermenu - o2.ordermenu).forEach(data=> {
          var menuItems_ = menuItems.filter(item => (item.headTitle1==data.displayName));
          menuFromDb.push(...menuItems_); // buat ngambil head menu
          data.type = 'link';
          
          if(data.url!='#'){
            if(data.childrens&&data.childrens.length>0){
              data.children = data.childrens.sort((o1, o2) => o1.ordermenu - o2.ordermenu)
            }
            menuFromDb.push(data);
          }
        });
        this.menuItems= menuFromDb;
      }else{
        this.menuItems = menuItems;
      } 
      this.router.events.subscribe((event) => {
        if (event instanceof NavigationEnd) {
          menuItems.filter(items => {
            if (items.url === event.url) {
              this.urlTerm=event.url
              this.setNavActive(items);
            }
            if (!items.childrens) { return false; }
            items.childrens.filter(subItems => {
              if (subItems.url === event.url) {
                this.urlTerm=event.url
                this.setNavActive(subItems);
              }
              if (!subItems.childrens) { return false; }
              subItems.childrens.filter(subSubItems => {
                if (subSubItems.url === event.url) {
                  this.setNavActive(subSubItems);
                }
              });
            });
          });
        }
      });
    });
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.width = event.target.innerWidth - 500;
  }

  sidebarToggle() {
    this.navServices.collapseSidebar = !this.navServices.collapseSidebar;
  }

  // Active Nave state
  setNavActive(item) {
    this.menuItems.filter(menuItem => {
      if (menuItem !== item) {
        menuItem.active = false;
      }
      if (menuItem.childrens && menuItem.childrens.includes(item)) {
        menuItem.active = true;
      }
      if (menuItem.childrens) {
        menuItem.childrens.filter(submenuItems => {
          if (submenuItems.childrens && submenuItems.childrens.includes(item)) {
            menuItem.active = true;
            submenuItems.active = true;
          }
        });
      }
    });
  }

  changeRouterLink(item){
    console.log("KLIK MENU = "+item.active)
    if(this.urlTerm==item.url){
      item.active=true
      window.location.href=item.url
    }else{
      item.active=false
    }
  }

  // Click Toggle menu
  toggletNavActive(item) {
    if (!item.active) {
      this.menuItems.forEach(a => {
        if (this.menuItems.includes(item)) {
          a.active = false;
        }
        if (!a.childrens) { return false; }
        a.childrens.forEach(b => {
          if (a.childrens.includes(item)) {
            b.active = false;
          }
        });
      });
    }
    item.active = !item.active;
  }


  // For Horizontal Menu
  scrollToLeft() {
    if (this.margin >= -this.width) {
      this.margin = 0;
      this.leftArrowNone = true;
      this.rightArrowNone = false;
    } else {
      this.margin += this.width;
      this.rightArrowNone = false;
    }
  }

  scrollToRight() {
    if (this.margin <= -3051) {
      this.margin = -3464;
      this.leftArrowNone = false;
      this.rightArrowNone = true;
    } else {
      this.margin += -this.width;
      this.leftArrowNone = false;
    }
  }
  

}
