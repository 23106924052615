import { Component, PLATFORM_ID, Inject } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { map, delay, withLatestFrom } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { Idle, DEFAULT_INTERRUPTSOURCES } from '@ng-idle/core';
import { Keepalive } from '@ng-idle/keepalive';
import { Router } from '@angular/router';
import { ModalService } from './shared/services/modal.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { environment } from 'src/environments/environment';
import { ServerService } from './service/server.service';
declare var require
const Swal = require('sweetalert2')

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  
  // For Progressbar
  loaders = this.loader.progress$.pipe(
    delay(1000),
    withLatestFrom(this.loader.progress$),
    map(v => v[1]),
  );

  //For Session 
  idleState = 'Not started.';
  timedOut = false;
  lastPing?: Date = null;
  title = 'angular-idle-timeout';
  
  constructor(@Inject(PLATFORM_ID) private platformId: Object,
    private loader: LoadingBarService, 
    translate: TranslateService,
    private idle: Idle, 
    private keepalive: Keepalive, 
    private router: Router,
    private modalService: ModalService,
    private ngbModalService: NgbModal,
    private serverService: ServerService) {
    if (isPlatformBrowser(this.platformId)) {
      translate.setDefaultLang('en');
      translate.addLangs(['en', 'de', 'es', 'fr', 'pt', 'cn', 'ae']);
    }

    //SESSION 
    // sets an idle timeout of 5 seconds, for testing purposes.
    idle.setIdle(3600);
    // sets a timeout period of 5 seconds. after 10 seconds of inactivity, the user will be considered timed out.
    idle.setTimeout(900);
    // sets the default interrupts, in this case, things like clicks, scrolls, touches to the document
    idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

    idle.onIdleEnd.subscribe(() => { 
      this.idleState = 'No longer idle.'
      console.log(this.idleState);
      this.reset();
    });

    idle.onTimeout.subscribe(() => {
      this.idleState = 'Timed out!';
      this.timedOut = true;
      console.log(this.idleState);
      this.resetPage()
      this.router.navigate(['/']);
    });

    idle.onIdleStart.subscribe(() => {
      this.idleState = 'You\'ve gone idle!'
      console.log(this.idleState);
      let timerInterval
      Swal.fire({
        title: this.idleState,
        text: 'You will time out in 5 seconds!',
        icon: 'warning',
        timer: 5000,
        timerProgressBar: true,
        showCancelButton: true,
        confirmButtonText: 'Logout',
        cancelButtonText: 'Stay'
      }).then((result) => {
        if (result.isConfirmed) {
          this.resetPage()
          this.router.navigate(['/landing/home'])
        }
      });
  });

  idle.onTimeoutWarning.subscribe((countdown) => {
    this.idleState = 'You will time out in ' + countdown + ' seconds!'
    console.log(this.idleState);
  });

  // sets the ping interval to 15 seconds
  keepalive.interval(15);

  keepalive.onPing.subscribe(() => this.lastPing = new Date());
  }

  reset() {
    this.idle.watch();
    // this.idleState = 'Started.';
    this.timedOut = false;
  }

  resetPage(){
    sessionStorage.clear();
    localStorage.clear()
    this.modalService.closeModal()
    this.ngbModalService.dismissAll()
  }

}
