import { Injectable, OnDestroy } from '@angular/core';
import { Subject, BehaviorSubject, fromEvent } from 'rxjs';
import { takeUntil, debounceTime } from 'rxjs/operators';
import { Router } from '@angular/router';
import { Menus } from 'src/app/shared/components/sidebar/menu';


// Menu
export interface Menu {
	headTitle1?: string,
	headTitle2?: string,
	path?: string;
	title?: string;
	icon?: string;
	type?: string;
	badgeType?: string;
	badgeValue?: string;
	active?: boolean;
	bookmark?: boolean;
	children?: Menu[];
}

@Injectable({
	providedIn: 'root'
})

export class NavService implements OnDestroy {

	private unsubscriber: Subject<any> = new Subject();
	public  screenWidth: BehaviorSubject<number> = new BehaviorSubject(window.innerWidth);

	// Search Box
	public search: boolean = false;

	// Language
	public language: boolean = false;
	
	// Mega Menu
	public megaMenu: boolean = false;
	public levelMenu: boolean = false;
	public megaMenuColapse: boolean = window.innerWidth < 1199 ? true : false;

	// For Horizontal Layout Mobile
	public horizontal: boolean = window.innerWidth < 991 ? false : true;

	// Collapse Sidebar
	public collapseSidebar: boolean = window.innerWidth < 991 ? true : false;

	// Full screen
	public fullScreen: boolean = false;


	constructor(private router: Router) {
		this.setScreenWidth(window.innerWidth);
		fromEvent(window, 'resize').pipe(
			debounceTime(1000),
			takeUntil(this.unsubscriber)
		).subscribe((evt: any) => {
			this.setScreenWidth(evt.target.innerWidth);
			if (evt.target.innerWidth < 991) {
				this.collapseSidebar = true;
				this.megaMenu = false;
				this.levelMenu = false;
			}
			if(evt.target.innerWidth < 1199) {
				this.megaMenuColapse = true;
			}
		});
		if(window.innerWidth < 991) { // Detect Route change sidebar close
			this.router.events.subscribe(event => { 
				this.collapseSidebar = true;
				this.megaMenu = false;
				this.levelMenu = false;
			});
		}
	}

	ngOnDestroy() {
		this.unsubscriber.next();
		this.unsubscriber.complete();
	}

	private setScreenWidth(width: number): void {
		this.screenWidth.next(width);
	}

	MENUITEMS: Menus[] = [
		{
			url: '/dashboard/default', displayName: 'Beranda', icon: 'home', type: 'link'
		},
		{
			headTitle1: 'Mitra', headTitle2: 'Menu Yang Digunakan Mitra',
		},
		{
			url: '/mitra/kerja-sama', displayName: 'Pengajuan Kerjasama', icon: 'clipboard', type: 'link'
		},
		{
			url: '/mitra/aplikasi-saya', displayName: 'Aplikasi Saya', icon: 'archive', type: 'link'
		},
		{
			url: '/mitra/dokumentasi', displayName: 'Dokumentasi API', icon: 'book-open', type: 'link'
		},
		{
			url: '/mitra/list-user', displayName: 'Manajemen Pengguna', icon: 'user', type: 'link'
		},
		{
			url: '/mitra/list-group', displayName: 'Manajemen Group', icon: 'users', type: 'link'
		},
		{
			headTitle1: 'API Development', headTitle2: 'Menu Yang Digunakan IT',
		},
		{
			url: '/development/path', displayName: 'url', icon: 'database', type: 'link'
		},
		{
			url: '/development/signature', displayName: 'Signature', icon: 'edit-2', type: 'link'
		},
		{
			displayName: 'Request Body', type: 'sub', icon: 'arrow-left-circle', active: false, childrens: [
				{ url: '/development/request-parameter', displayName: 'Paramater', type: 'link' },
				{ url: '/development/role-parameter/', displayName: 'Role Parameter', type: 'link' },
			]
		},
		{
			displayName: 'Response Body', type: 'sub', icon: 'arrow-right-circle', active: false, childrens: [
				{ url: '/development/response-parameter', displayName: 'Paramater', type: 'link' },
			]
		},
		{
			url: '/development/path-mapping', displayName: 'Path Mapping', icon: 'layers', type: 'link'
		},
		{
			url: '/development/message-generator', displayName: 'Message Generator', icon: 'refresh-ccw', type: 'link'
		},
		{
			url: '/development/iso-request', displayName: 'ISO Request', icon: 'arrow-right', type: 'link'
		},
		{
			url: '/development/iso-response', displayName: 'ISO Response', icon: 'arrow-left', type: 'link'
		},
		{
			url: '/development/playground', displayName: 'Playground', icon: 'airplay', type: 'link'
		},
		{
			headTitle1: 'API Management', headTitle2: 'Menu Yang Digunakan IT',
		},
		{
			url: '/management/services', displayName: 'Manajemen Services', icon: 'hard-drive', type: 'link'
		},
		{
			url: '/management/routes', displayName: 'Manajemen Routes', icon: 'git-merge', type: 'link'
		},
		{
			url: '/management/consumers', displayName: 'Manajemen Consumer', icon: 'activity', type: 'link'
		},
		{
			headTitle1: 'Consumer Management', headTitle2: 'Menu Yang Digunakan IT',
		},
		{
			url: '/consumer/admin-bank', displayName: 'Manajemen Admin Bank', icon: 'user', type: 'link'
		},
		{
			url: '/consumer/paket-biaya', displayName: 'Manajemen Paket Biaya', icon: 'credit-card', type: 'link'
		},
		{
			url: '/consumer/partner', displayName: 'Manajemen Mitra', icon: 'users', type: 'link'
		},
		{
			url: '/consumer/approval-partner', displayName: 'Persetujuan Mitra', icon: 'check-circle', type: 'link'
		},
	];

	MEGAMENUITEMS: Menu[] = [
		{
			title: 'Error Pages', type: 'sub', active: true, children: [
				{ path: 'javascript:void(0);', title: 'Error Page 400', type: 'extLink' },
				{ path: 'javascript:void(0);', title: 'Error Page 401', type: 'extLink' },
				{ path: 'javascript:void(0);', title: 'Error Page 403', type: 'extLink' },
				{ path: 'javascript:void(0);', title: 'Error Page 404', type: 'extLink' },
				{ path: 'javascript:void(0);', title: 'Error Page 500', type: 'extLink' },
				{ path: 'javascript:void(0);', title: 'Error Page 503', type: 'extLink' },
			]
		},
		{
			title: 'Usefull Pages', type: 'sub', active: false, children: [
				{ path: 'javascript:void(0);', title: 'Search Pages', type: 'extLink' },
				{ path: 'javascript:void(0);', title: 'Unlock User', type: 'extLink' },
				{ path: 'javascript:void(0);', title: 'Forgot Password', type: 'extLink' },
				{ path: 'javascript:void(0);', title: 'Reset Password', type: 'extLink' },
				{ path: 'javascript:void(0);', title: 'Maintenance', type: 'extLink' }
			]
		},
		{
			title: 'Email templates', type: 'sub', active: false, children: [
				{ path: 'http://admin.pixelstrap.com/cuba/theme/basic-template.html', title: 'Basic Email', type: 'extTabLink' },
				{ path: 'http://admin.pixelstrap.com/cuba/theme/email-header.html', title: 'Basic With Header', type: 'extTabLink' },
				{ path: 'http://admin.pixelstrap.com/cuba/theme/template-email.html', title: 'Ecomerce Template', type: 'extTabLink' },
				{ path: 'http://admin.pixelstrap.com/cuba/theme/template-email-2.html', title: 'Email Template 2', type: 'extTabLink' },
				{ path: 'http://admin.pixelstrap.com/cuba/theme/ecommerce-templates.html', title: 'Ecommerce Email', type: 'extTabLink' },
				{ path: 'http://admin.pixelstrap.com/cuba/theme/email-order-success.html', title: 'Order Success', type: 'extTabLink' }
			]
		},
		{
			title: 'Coming Soon', type: 'sub', active: false, children: [
				{ path: 'javascript:void(0);', title: 'Coming Simple', type: 'extLink' },
				{ path: 'javascript:void(0);', title: 'Coming BG Image', type: 'extLink' },
				{ path: 'javascript:void(0);', title: 'Coming BG Video', type: 'extLink' }
			]
		},
	];

	LEVELMENUITEMS: Menu[] = [
		{
			path: 'javascript:void(0);', title: 'File Manager', icon: 'git-pull-request', type: 'extLink'
		},
		{
			title: 'Users', icon: 'users', type: 'sub', active: false, children: [
				{ path: 'javascript:void(0);', title: 'All Users', icon: 'users', type: 'extLink' },
				{ path: 'javascript:void(0);', title: 'User Profile', icon: 'users', type: 'extLink' },
				{ path: 'javascript:void(0);', title: 'Edit Profile', icon: 'users', type: 'extLink' },
			]
		},
		{ path: 'javascript:void(0);', title: 'Bookmarks', icon: 'heart', type: 'extLink' },
		{ path: 'javascript:void(0);', title: 'Calender', icon: 'calendar', type: 'extLink' },
		{ path: 'javascript:void(0);', title: 'Social App', icon: 'zap', type: 'extLink' }
	];


	// Array
	items = new BehaviorSubject<Menus[]>(this.MENUITEMS);
	megaItems = new BehaviorSubject<Menu[]>(this.MEGAMENUITEMS);
	levelmenuitems = new BehaviorSubject<Menu[]>(this.LEVELMENUITEMS);

}
