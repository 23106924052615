// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    production: false,
    firebase: {
      apiKey: "Your Api Key",
      authDomain: "Your Auth Domain",
      databaseURL: "Your Database Url",
      projectId: "Your Project Id",
      storageBucket: "Your StorageBucket url",
      messagingSenderId: "Your Sender Id"
    },
    tenant:"Bank Banten",
    address:"Jl. Sudirman Lingkungan Kemang, Ruko Sembilan No. 8b-9a, Kelurahan Sumur Pecung, Kecamatan Serang, Banten",
    phone:"Telp. 0254 – 7917346 / 0254 – 7924060",
    fax:"",
    logo:"assets/images/logo/Logo-bank-banten.png",
    logo_icon:"assets/images/logo/Logo-bank-banten-icon.png",
    primary_color:'red',
    secondary_color: '#992020',
    urlMitraProd:"https://portal-bis-banten.telkomsigma.dev/",
    urlBankProd:"https://portaladmin-bis-banten.telkomsigma.dev/",
    isGeneratedKey:false,
    isPlaygroundMitra:false,
    path:{
      base:"https://api-banten.telkomsigma.dev",
      select2:"/select2",
      partner:{
        base:"/partner",
        user:"/user",
        login:"/login",
        enable:"/enable-disable",
        group:"/group",
        business_criteria:"/business-criteria",
        business_category:"/business-category",
        submission:"/submission",
        data:"/data",
        document:"/document",
        forgotPassword:"/forgot-password",
        resetPassword:"/reset-password",
        pathEnvi:"/path-environment",
        path:"/path",
        checkStatus:"/check-status",
        application_partner:"/application-partner",
        application_submission:"/application-submission",
        dasboard:"/partnerdashboard",
        serviceHit:"/service-hit",
        fileTye:"/file-type",
        partnerdashboard:"/partnerdashboard",
        merchant_category_code:"/merchant-category-code",
        channel:"/channel",
        user_session:"/user-session",
        terminate_user_session:"/terminate-user-session",
        datatable:"/datatables",
        pathTSceneDoc:"/path-tscen-doc",
        publicKey:"/public-key-by",
        withGroup:"/with-group"
      },
      api_doc:{
        base:"/apidoc",
        find_by_id:"/find-by-id/",
        get_all:"get-all",
        playground_execute:"/playground-mitra"
      },
      report:{
        base: "/feemanagement",
        settlement:"/settlement",
        reconcile:"/reconcile",
        invoice:"/invoice"
      }
    }
  };
  
  export const LOGIN_MITRA_URL = environment.path.base + environment.path.partner.base + environment.path.partner.user + environment.path.partner.login
  export const FORGOT_PASSWORD = environment.path.base + environment.path.partner.base + environment.path.partner.user + environment.path.partner.forgotPassword
  export const RESET_PASSWORD = environment.path.base + environment.path.partner.base + environment.path.partner.user + environment.path.partner.resetPassword
  
  //user
  export const ENABLE_DISABLE_USER = environment.path.base + environment.path.partner.base + environment.path.partner.user + environment.path.partner.enable
  export const USER_URL =  environment.path.base + environment.path.partner.base + environment.path.partner.user
  
  //group
  export const GROUP_SELECT2 = environment.path.base + environment.path.partner.base + environment.path.partner.group + environment.path.select2
  
  //apidoc
  export const GET_ALL_API_PUBLIC = environment.path.base + environment.path.api_doc.base + environment.path.api_doc.get_all
  export const FIND_API_BY_ID_PUBLIC = environment.path.base + environment.path.api_doc.base + environment.path.api_doc.find_by_id
  
  //submisson
  export const BUSINESS_CRITERIA_SELECT2 = environment.path.base + environment.path.partner.base +environment.path.partner.business_criteria + environment.path.select2
  export const BUSINESS_CATEGORY_SELECT2 = environment.path.base + environment.path.partner.base+ environment.path.partner.business_category + environment.path.select2
  export const SUBMISSON_URL = environment.path.base + environment.path.partner.base +  environment.path.partner.submission +  environment.path.partner.data
  export const FIND_SUBMISSON_BY_ID = environment.path.base + environment.path.partner.base +  environment.path.partner.submission +  environment.path.partner.data + '/'
  export const UPLOAD_LEGALITY_DOKUMEN = environment.path.base + environment.path.partner.base + environment.path.partner.submission + environment.path.partner.document
  export const CHECK_STATUS_SUBMISSION = environment.path.base + environment.path.partner.base + environment.path.partner.submission + environment.path.partner.checkStatus
  export const SUBMIT_SUBMISSION = environment.path.base + environment.path.partner.base + environment.path.partner.submission
  export const GET_FILE_TYPE = environment.path.base + environment.path.partner.base + environment.path.partner.fileTye 
  
  //path-envi
  export const ENVIRONMENT_TYPE_SELECT2 = environment.path.base + environment.path.partner.base + environment.path.partner.pathEnvi + environment.path.select2
  export const ENVIRONMENT_PATH = environment.path.base + environment.path.partner.base + environment.path.partner.path
  export const APPLICATION_PARTNER = environment.path.base + environment.path.partner.base + environment.path.partner.application_partner
  
  export const DASHBOARD_DETAIL = environment.path.base + environment.path.partner.dasboard + environment.path.partner.serviceHit
  
  /*
   * For easier debugging in development mode, you can import the following file
   * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
   *
   * This import should be commented out in production mode because it will have a negative impact
   * on performance if an error is thrown.
   */
  // import 'zone.js/plugins/zone-error';  // Included with Angular CLI.