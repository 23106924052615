import { Component, OnInit, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { NavService } from '../../services/nav.service';
import { LayoutService } from '../../services/layout.service';

@Component({
  selector: 'scc-input',
  templateUrl: './scc-input.component.html',
  styleUrls: ['./scc-input.component.scss']
})
export class SccInpuComponent {


}
