import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ProfileService {

  fullname: string;

  company: string;

  group: string;

  constructor() {
    if(localStorage.getItem('userInfo')){
      let userInfo = JSON.parse(localStorage.getItem('userInfo'));
      this.fullname = userInfo.fullname;
      this.company = userInfo.company;
      this.group = userInfo.group;
    }
  }

  getFullname() {
    return this.fullname;
  }

  setFullname(fulname: string) {
    this.fullname = fulname;
  }

  getCompany() {
    return this.company;
  }

  setCompany(company: string) {
    this.company = company;
  }

  getGroup() {
    return this.group;
  }

  setGroup(group: string) {
    this.group = group;
  }
  
}
